/*--------------------------------------------*\
    $CONTENTS

    Panels and collapse components.
    Based on Bootstrap.
\*--------------------------------------------*/
/**
 * Variables.....................Variable declarations and overrides
 * Bootstrap-Import..............Required import for BS component
 * Panels-Base...................Base styles for all components
 * Teacher-Accordion.............Teacher collapse component
 */

/*------------------------------------*\
    $Variables
\*------------------------------------*/
$panel-border-radius: 0px;
$panel-default-text: $black;
$panel-default-heading-bg: $gray-250;
$panel-bg: $gray-100;
$panel-body-padding: 15px;

/*------------------------------------*\
    $Bootstrap-Import
\*------------------------------------*/
@import "bootstrap-sass/assets/stylesheets/bootstrap/mixins/panels";
@import "bootstrap-sass/assets/stylesheets/bootstrap/panels";

/*------------------------------------*\
    $Panels-Base
\*------------------------------------*/
.panel-pa-info {
  .panel {
    border: 0px;
  }

  .panel-heading {
    background: inherit;
  }

  .panel-title {
    a {
      display: inline-block;
      color: $orange;
      font-size: 1.1em;
      text-decoration: none;

      .caret {
        top: 10px;
        width: 10px;
        height: 10px;
        border: solid black;
        border-width: 3px 3px 0 0;
        margin-bottom: 0px;
        margin-left: 8px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        transition: transform 0.15s;
      }
      &.collapsed .caret {
        top: 6px;
        margin-bottom: 6px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
        transition: transform 0.15s;
      }
    }
  }
}

/*------------------------------------*\
    $Teacher-Accordion
\*------------------------------------*/
.teacher-accordion {
  //@include heavy-bar();

  .panel {
    border: 0px;
  }

  .panel-heading {
    background: inherit;
    //@include light-bar(border-bottom, $color:darken($gray, 10%));
  }

  .panel-title {
    & a {
      position: relative;
      display: block;
      color: $black;
      font-size: 1.1em;
      text-decoration: none;

      & .caret {
        @include ff-smoothing();

        position: absolute;
        top: 50%;
        right: 0px;

        border-top: 7px solid;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        margin-top: -4px;
      }
    }
  }

  .panel-body {
    padding: 0px;
    border-top: 0px !important;

    img {
      width: 100%;
    }

    .teacher-text {
      padding: $panel-body-padding;
    }
  }
}
