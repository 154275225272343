.markdown-editor {
  position: relative;
  * {
    box-sizing: content-box;
  }

  &--with-uploads {
    display: flex;
    flex-direction: column;

    @include small {
      flex-direction: row;
    }

    .dropzone {
      height: 300px;
      margin: 0.5rem 0 0 0;
      overflow-y: auto;

      @include small {
        width: 20%;
        margin: 0 0 0 0.5rem;
      }
    }

    .dropzone,
    .xui-mdupload-zone {
      box-sizing: border-box;
    }
  }

  &__error-message {
    margin: 0;
    color: $primary-color;
  }

  &__resize-btn {
    position: absolute;
    z-index: $z-index-sm;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    background: transparent;
    color: $gray-800;
    &::after {
      content: "\f065";
      font-family: "Font Awesome 6 Pro";
    }
    &--with-scrollbar {
      right: 15px;
    }
    &--collapse {
      right: 0;
      &::after {
        content: "\f066";
      }
    }
    &:hover {
      border: 1px solid $gray-250;
      background: $gray-50;
    }
  }

  textarea[data-behavior="markdown-form-input"] {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    resize: none;
  }
}

.upload_fields_wrapper {
  position: relative;
  flex-grow: 1;

  @include small {
    width: 80%;
  }
}

.upload_fields {
  position: absolute;
  bottom: 0;
  width: 100%;
  resize: none;
}

/*------------------------------------*\
    $ToastUI overrides
\*------------------------------------*/

.toastui-editor-md-container {
  background-color: white;
}

.toastui-editor-toolbar {
  // The toolbar button for h2 headings was taking the primary color
  h2 {
    color: $text-color;
  }
}

// Overrides for the preview section
.toastui-editor-contents {
  font-size: 14px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    border-bottom: 0;
    margin: 1.5em 0 0 0;
  }

  p,
  li {
    margin: 1.5em 0 0 0;
  }

  blockquote {
    p {
      margin-top: 0;
      color: $gray-800;
      font-size: 0.75em;
    }
  }

  ol,
  ul {
    margin: 1.5em 1em;

    li {
      margin-top: 0.5em;
    }
  }

  ol > li::before {
    color: inherit;
  }

  ul > li::before {
    background-color: $text-color;
  }

  a {
    color: $link-color;
    text-decoration: none;
    &:hover {
      color: $link-color-hover;
      text-decoration: underline;
    }
  }

  pre {
    padding: 0;
    border: 1px solid #ccc;
    margin: 1.5em 0 0;
    background-color: #f5f5f5;
  }

  table {
    border: none;
    thead tr {
      border-bottom: 2px solid $black;
      background: rgba($gray, 0.5);
    }
    td,
    th {
      height: auto;
      padding: 2px;
      border: none;
      margin: 2px;
    }

    th {
      background: inherit;
      color: $text-color;
      font-weight: bold;
    }
    tbody tr {
      &:nth-child(odd) {
        background: rgba($white, 0.5);
      }
      &:nth-child(even) {
        background: rgba($gray, 0.5);
      }
    }
  }

  del {
    color: $text-color;
  }

  & :first-child {
    margin-top: 15px;
  }

  & :last-child {
    margin-bottom: 15px;
  }
}

// Ensure multiline backquotes have same text color in "write" mode
.toastui-editor-md-marked-text,
.toastui-editor-md-block-quote {
  color: $gray-800;
}

// Improve color contrast:
// Change elements using #999 to use $gray-600
.toastui-editor-md-meta,
.toastui-editor-md-html,
.toastui-editor-md-link.toastui-editor-md-link-url.toastui-editor-md-marked-text,
.toastui-editor-contents blockquote p,
.toastui-editor-contents blockquote ul,
.toastui-editor-contents blockquote ol,
.toastui-editor-contents blockquote,
.toastui-editor-contents del,
.ProseMirror .placeholder {
  color: $gray-600;
}
