/*------------------------------------*\
    $CONTENTS

    Learning Items
\*------------------------------------*/
/**
 * Buttons......Button styles on learning items page
 */

/*------------------------------------*\
    Buttons
\*------------------------------------*/

.item-mgmt-btn {
  display: inline-block;
}

.edit-item-btn {
  margin-right: 4px;
}
