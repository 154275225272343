.social-sharing {
  display: flex;
  width: 100%;
  gap: 10px;

  &__button {
    display: flex;
    overflow: hidden;
    flex-basis: 100px;
    flex-grow: 1;
    align-items: center;
    padding: 5px 10px;
    color: $white;
    @include font-s;

    &:hover {
      filter: brightness(120%);
    }

    &:focus {
      outline: 1px solid $white;
    }

    &:active,
    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
    }

    &--facebook {
      background-color: #3b5998;
    }

    &--linkedin_add {
      background-color: #0077b5;
    }

    &--mail {
      background-color: #999;
    }
  }

  &__text {
    width: auto;
    margin-left: 5px;
  }
}
