.meter {
  &__label {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    font-weight: normal;
    transform: translate(-50%, -50%);
  }

  position: relative;
  width: 100%;
  height: 20px;

  meter[value] {
    display: block;
    width: 100%;
    height: 20px;
    border: none;

    /* Reset the default appearance */
    -webkit-appearance: none;
    appearance: none;

    /* Override background gradient in Firefox */
    background: $gray-100;
  }

  /* Chrome and Safari */
  meter::-webkit-meter-bar {
    height: 20px;
    background: $gray-100;
  }

  meter::-webkit-meter-optimum-value {
    background: $success-500;
  }

  /* Firefox */
  meter::-moz-meter-bar {
    background: $success-500;
  }

  &--info {
    /* Chrome and Safari */
    meter::-webkit-meter-optimum-value {
      background: $information-500;
    }

    /* Firefox */
    meter::-moz-meter-bar {
      background: $information-500;
    }
  }

  &--white {
    meter[value] {
      background: $white;
    }

    /* Chrome and Safari */
    meter::-webkit-meter-bar {
      background: $white;
    }
  }
}
