@mixin slider-theme(
  $scrollbutton-background-color,
  $scrollbutton-icon-color,
  $scrollbar-color,
  $scrollbar-color-hover
) {
  .slider__content {
    scrollbar-color: $scrollbar-color transparent;
    // Style scrollbar indicator
    // Firefox: Limited customization available
    scrollbar-width: thin;

    // Chrome & Safari
    &::-webkit-scrollbar {
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      border-right: 15px solid transparent;
      border-left: 15px solid transparent;
      background: $scrollbar-color;
      background-clip: content-box;

      @include screen-size-sm-and-up {
        border: 0;
        background-clip: initial;
      }
    }

    &::-webkit-scrollbar-thumb {
      &:hover {
        background: $scrollbar-color-hover;
      }
    }

    &::-webkit-scrollbar-track {
      @include screen-size-sm-and-up {
        box-shadow: inset 0 0 2px $scrollbar-color-hover;
      }
    }
  }

  .slider__scroll-button {
    background-color: rgba($scrollbutton-background-color, 0.65);

    .xi-icon {
      color: $scrollbutton-icon-color;
    }

    &:hover {
      background-color: rgba($scrollbutton-background-color, 0.75);
    }
  }
}

.slider {
  $button-width: 50px;
  $content-spacing: 10px;
  position: relative;
  display: flex;
  width: 100%;

  &.slider--light {
    @include slider-theme(
      $scrollbutton-background-color: $white,
      $scrollbutton-icon-color: $gray-800,
      $scrollbar-color: $gray-250,
      $scrollbar-color-hover: $gray-500
    );
  }

  &.slider--dark {
    @include slider-theme(
      $scrollbutton-background-color: $gray-800,
      $scrollbutton-icon-color: $white,
      $scrollbar-color: $gray-600,
      $scrollbar-color-hover: $gray-500
    );
  }

  &.slider--custom {
    @include slider-theme(
      $scrollbutton-background-color: $slider-scrollbutton-background-color,
      $scrollbutton-icon-color: $slider-scrollbutton-icon-color,
      $scrollbar-color: $slider-scrollbutton-color,
      $scrollbar-color-hover: $slider-scrollbutton-color-hover
    );
  }

  &__content {
    display: flex;
    min-width: 100%;
    flex-wrap: nowrap;
    padding: 0 15px;
    // Scroll with momentum on iOS
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    scroll-padding: $content-spacing;
    scroll-snap-type: x mandatory;

    @include screen-size-s-and-up {
      scroll-padding: $button-width;
    }

    @include screen-size-sm-and-up {
      padding: 0;
    }

    // To make the button visibility logic work we need to ensure that the last item
    // will be totally visible. We do this by adding some padding.
    // Right padding on scrollable containers is being ignored on Safari. We're adding
    // it to a pseudo-element instead, which will work on all browsers.
    &::after {
      padding-right: 1px;
      content: "";
    }
  }

  // Target all elements in content but the intersectors
  &__item {
    flex: 0 0 90%;
    margin-right: $content-spacing;
    // align items to center to show item left and right because there are no scroll buttons
    scroll-snap-align: center;
    // Remove last item's margin (without counting the intersector
    // element) to ensure right page alignment
    &:nth-last-of-type(2) {
      margin-right: 0;
    }

    @include screen-size-s-and-up {
      flex-basis: 45%;
      // align items to start as scroll buttons are visible
      scroll-snap-align: start;
    }

    @include screen-size-sm-and-up {
      flex-basis: 30%;
    }

    @include screen-size-m-and-up {
      flex-basis: 23%;
    }

    // If items have their own margin, reset it. Use slider's margin instead.
    > * {
      margin: 0;
    }

    // Don't show box shadows in the slider
    > * {
      box-shadow: none;
    }
  }

  &__scroll-button {
    position: absolute;
    z-index: 1;
    left: 0;

    display: none;
    width: $button-width;
    height: 100%;
    border: none;
    transition: transform 0.4s ease;

    @include screen-size-s-and-up {
      display: block;
    }

    .xi-icon {
      // Prevent animation flickering in Chrome
      backface-visibility: hidden;
      transition: transform 0.4s ease;
    }

    &:hover {
      transform: scaleX(1.1) translateX(2px);

      .xi-icon {
        transform: scaleX(0.9) translateX(3px);
      }
    }

    &--right {
      right: 0;
      left: auto;
      &:hover {
        transform: scaleX(1.1) translateX(-2px);
        .xi-icon {
          transform: scaleX(0.9) translateX(-3px);
        }
      }
    }
  }

  // Hide intersectors on smaller screens, to not trigger a change of the IntersectionObserver.
  [data-slider-target="intersector-left"],
  [data-slider-target="intersector-right"] {
    display: none;

    @include screen-size-s-and-up {
      display: block;
    }
  }
}
