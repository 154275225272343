/* -------------------------------------------- *\
    $CONTENTS

    Font definitions
    Font usage and style adjustments
\* -------------------------------------------- */

// Source:
// https://google-webfonts-helper.herokuapp.com/fonts/ibm-plex-sans?subsets=latin
// https://google-webfonts-helper.herokuapp.com/fonts/ibm-plex-mono?subsets=latin

@include declare-font-face("IBM Plex Sans", "IBM-Plex-Sans-Regular");
@include declare-font-face("IBM Plex Sans", "IBM-Plex-Sans-Bold", bold);
@include declare-font-face(
  "IBM Plex Sans",
  "IBM-Plex-Sans-RegularItalic",
  normal,
  italic
);
@include declare-font-face(
  "IBM Plex Sans",
  "IBM-Plex-Sans-BoldItalic",
  bold,
  italic
);
@include declare-font-face("IBM Plex Mono", "IBM-Plex-Mono-Regular");
