.course-progress {
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    @include font-s;
  }

  &__items {
    display: grid;
    margin: 15px 0 25px;
    gap: 10px;
    grid-template-columns: 1fr;

    @include screen-size-s-and-up {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__overall-score {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  &__overall-score-title {
    font-weight: bold;
    text-align: center;
  }

  &__overall-graded-score {
    display: flex;
    gap: 5px;
  }
}
