.collapsible-button-container {
  .btn-collapsible {
    padding-top: 10px;
  }
}

.collapsible-container[aria-expanded="false"] {
  .btn-read-less {
    display: none;
  }

  .line-clamp {
    --max-lines: 12;
    position: relative;
    overflow: hidden;
    max-height: calc(1.6em * var(--max-lines));
  }

  .line-clamp:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(
      rgba($xi-channel-background-color, 0),
      $xi-channel-background-color
    );
    content: "";
  }
}

.collapsible-container[aria-expanded="true"] {
  .btn-read-more {
    display: none;
  }

  .btn-read-less {
    display: block;
  }
}
