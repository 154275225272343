.gamification-badges-list {
  padding-left: 0px;
  list-style: none;

  .gamification-badge {
    display: inline-block;
    width: 150px;
    height: 150px;
    margin: 10px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    img {
      width: 150px;
      height: 150px;
    }
  }
}
