/*------------------------------------*\
    $CONTENTS

    Item Stats page
\*------------------------------------*/

.item-stats {
  &__nav {
    margin-top: 20px;
  }

  &__facts {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin-top: 0;
    margin-bottom: 20px;

    .facts-icon {
      margin-right: 15px;
      color: $black;
      font-size: 1.25em;
    }

    p + p:before {
      margin-right: 10px;
      margin-left: 10px;
      content: "•";
    }

    & > * {
      margin: 0;
      color: $gray-600;
      line-height: 14px;

      span {
        color: $black;
        font-weight: bold;
      }
    }
  }

  &__custom {
    .unknown {
      padding: 50px;

      .statement {
        font-size: 1.6em;
        text-align: center;
      }
    }

    .result {
      .no-submission {
        @extend .unknown;
      }
    }

    .quiz {
      .no-submission {
        @extend .unknown;
      }

      .unknown-question {
        color: #999;
        font-size: 1.3em;
        text-align: center;
      }

      .base_stats {
        font-weight: normal;
      }

      .kpi-item {
        padding-top: 10px;
      }

      score-card {
        --score-card-icon-background-border-radius: 4px;
        --score-card-icon-background-color: #{$gray};
        --score-card-height: 60px;
        --score-card-icon-font-size: 2.5em;
        --score-card-link-color: #{$link-color};
        --score-card-link-color-hover: #{$link-color-hover};
        --score-card-link-text-decoration: none;
      }

      .badge {
        background-color: #333333;
      }
    }
  }
}
