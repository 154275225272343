.section-progress {
  &__item {
    width: 100%;
    margin-bottom: 20px;
    box-shadow: $box-shadow-xs;
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 20px;
    gap: 40px;

    @include screen-size-sm-and-up {
      flex-direction: row;
      gap: 20px;
    }
  }

  &__material-items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
    gap: 5px;

    a {
      text-decoration: none;
    }
  }

  &__material-item {
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: $gray-100;

    @mixin item-background($color) {
      background:
        linear-gradient($color, $color) left bottom / 100% var(--percentage),
        $gray-100;
      background-clip: padding-box;
      background-repeat: no-repeat;
    }

    .xi-icon {
      color: $text-color;
      font-size: 16px;
    }

    &--completed {
      @include item-background($success-500);
    }

    &--critical {
      @include item-background($error-500);
    }

    &--warning {
      @include item-background($information-500);
    }

    &--optional {
      border: 1px, dashed, $gray-500;
      background-color: $gray-50;
    }
  }

  &__statistics {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include screen-size-s-and-up {
      flex-direction: row;
    }
    @include screen-size-sm-and-up {
      flex-direction: column;
    }
  }

  &__statistic-percentage {
    @include font-m;
    display: flex;
    align-items: center;
    color: $gray-800;
    text-align: right;
    @include screen-size-sm-and-up {
      justify-content: flex-end;
    }

    span {
      color: $gray-800;
      @include font-sm;
      @include screen-size-sm-and-up {
        padding-right: 5px;
        border-right: 2px solid $gray-250;
      }
    }
  }

  &__statistic {
    display: grid;
    grid-template-columns: auto;
    @include screen-size-sm-and-up {
      gap: 10px;
      grid-template-columns: auto 230px;
    }
  }

  &__statistic-item {
    display: flex;
    align-items: center;
    color: $gray-800;
    font-weight: bold;
    gap: 5px;
  }

  &__statistic-item-data {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    @include screen-size-sm-and-up {
      flex-wrap: nowrap;
    }
  }

  &__titlebar {
    display: grid;
    width: 100%;
    padding: 10px;
    border: none;
    background-color: $gray-100;
    gap: 10px;
    grid-template-areas:
      "title"
      "info"
      "score-bars";

    @include screen-size-sm-and-up {
      grid-template-areas:
        "title info"
        "score-bars score-bars";
    }

    @include screen-size-m-and-up {
      grid-template-areas: "title score-bars info";
      grid-template-columns: 300px auto 180px;
    }

    &[aria-expanded="false"] {
      + .section-progress__content {
        display: none;
      }

      .section-progress__icon {
        &::before {
          content: "\f078";
        }
      }
    }

    &[aria-expanded="true"] {
      + .section-progress__content {
        display: flex;
      }

      .section-progress__icon {
        &::before {
          content: "\f077";
        }
      }
    }
  }

  &__icon {
    margin-right: 10px;
    font: var(--fa-font-regular);
  }

  &__trigger {
    display: flex;
    align-items: center;
    font-weight: bold;
    grid-area: title;
  }

  &__title {
    text-align: left;
    @include truncate-text(1);
  }

  &__score-bar {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: end;
    margin: 0 auto;
    gap: 10px;
    grid-area: score-bars;
    @include screen-size-sm-and-up {
      flex-direction: row;
      margin: 0 auto;
    }

    @include screen-size-m-and-up {
      justify-content: center;
      margin: 0;
    }
  }

  &__score {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
    gap: 3px;
    > label {
      margin-top: 0;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
    }

    @include screen-size-sm-and-up {
      flex-direction: column;
      > label {
        min-width: auto;
      }
    }

    @include screen-size-m-and-up {
      width: auto;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  &__score-empty,
  &__score-progress {
    width: 100%;
    p {
      margin-bottom: 0;
    }

    @include screen-size-sm-and-up {
      min-width: 150px;
    }
  }

  &__completed-items {
    color: $gray-600;
    grid-area: info;
    white-space: nowrap;
    @include screen-size-sm-and-up {
      justify-self: end;
    }
    p {
      margin-bottom: 0;
    }
  }

  &__legend {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &__legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  &__legend-icon {
    min-width: 20px;
    min-height: 20px;
    margin-right: 10px;

    background-color: $gray-100;

    &--completed {
      background-color: $success-500;
    }

    &--critical {
      background: linear-gradient(to top, $error-500 20%, $gray-100 0);
    }

    &--warning {
      background: linear-gradient(to top, $information-500 50%, $gray-100 0);
    }

    &--optional {
      border: 1px, dashed, $gray-500;
      background-color: $gray-50;
    }

    span {
      margin-left: 5px;
    }
  }
}
