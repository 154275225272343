/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * Variables..............Variable declarations and overrides
 * Progress-Certificates......Base styling for progress certificates
 */

/*------------------------------------*\
    $Variables
\*------------------------------------*/
$progress-card-spacing: 10px;

/*------------------------------------*\
    $Progress Page
\*------------------------------------*/

#progress-page {
  .progress-headline {
    margin: 0 $progress-card-spacing;
  }

  .progress-card {
    display: flex;
    box-sizing: border-box;
    flex: 1 1;
    margin: $progress-card-spacing;
    background: $white;
    box-shadow: $box-shadow-xs;

    .card-container {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 10px 16px;

      h5 {
        margin-top: 0;
        font-weight: bold;
      }
    }

    &__callout-download {
      width: 100%;

      .callout {
        margin: 0;
      }
    }
  }

  .certificate-buttons {
    display: flex;
    flex-wrap: wrap;
  }
}
