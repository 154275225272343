/*-------------------------------------*\
    $CONTENTS

    Grid component. Based on Bootstrap.
\*-------------------------------------*/
/**
 * Variables...................Variables and Bootstrap SASS-Var overrides
 * Bootstrap-Import............Required import for BS component
 * Grid-Styles.................Styling for the grid
 */

/*------------------------------------*\
    $Variables
\*------------------------------------*/
$screen-lg-min: 1400px;
$container-lg: 1340px + $grid-gutter-width;

$screen-md-max: 1400px;
$container-md: 100%;
$container-sm: 100%;

/*------------------------------------*\
    $Bootstrap-Import
\*------------------------------------*/
@import "bootstrap-sass/assets/stylesheets/bootstrap/mixins/clearfix";
@import "bootstrap-sass/assets/stylesheets/bootstrap/mixins/grid-framework";
@import "bootstrap-sass/assets/stylesheets/bootstrap/mixins/grid";
@import "bootstrap-sass/assets/stylesheets/bootstrap/grid";

/*------------------------------------*\
    $Grid-Styles
\*------------------------------------*/

.col-md-0 {
  width: 0px;
  height: 0px;
  margin-left: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.visible-to-xs {
  @media (min-width: $screen-xs-max + 1) {
    display: none !important;
  }
}

.visible-to-md {
  @media (min-width: $screen-md-min + 1) {
    display: none !important;
  }
}

.invisible-to-xs {
  @media (max-width: $screen-xs-max) {
    display: none !important;
  }
}

.invisible-to-md {
  @media (max-width: $screen-md-min + 1) {
    display: none !important;
  }
}

.col-centered {
  margin: 0 auto;
  float: none;
}
