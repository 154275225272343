/*------------------------------------*\
    $CONTENTS

    Styling for static pages.
\*------------------------------------*/
/**
 * Variables.............Variable declarations and overrides
 * Static-Elements.......Basic elements style
 */

/*------------------------------------*\
    $Variables
\*------------------------------------*/
$xi-static-page-background-color: $white !default;

/*------------------------------------*\
    $Static-Elements
\*------------------------------------*/

h1 {
  font-size: 1.8em;
}
h2 {
  font-size: 1.6em;
}

.MarkdownEditorPreview {
  font-size: 14px;
}

.RichTextItem-Content {
  font-size: 14px;
}

.RenderedMarkdown,
.toastui-editor-content {
  * {
    padding: 0;
    margin: 0;
  }

  & > * + * {
    margin-top: 1.5em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $text-color;
  }

  h1 {
    font-size: 2em;
    line-height: 1.2;
  }

  h2 {
    font-size: 1.7em;
    line-height: 1.2;
  }

  h3 {
    font-size: 1.5em;
    line-height: 1.3;
  }

  h4 {
    font-size: 1.4em;
    line-height: 1.4;
  }

  h5 {
    font-size: 1.2em;
    line-height: 1.4;
  }

  h6 {
    font-size: 1em;
    line-height: 1.5;
  }

  p,
  li {
    font-size: 1em;
    line-height: 1.6;
  }

  blockquote {
    padding: 0.5em;
    border-left-color: $gray-100;
    margin-right: 1em;
    margin-left: 1em;
    color: $gray-800;
    font-size: 0.9em;
  }

  ol,
  ul {
    margin-right: 2em;
    margin-left: 2em;
  }

  li {
    margin-top: 0.5em;
  }

  td,
  th {
    padding: 2px;
    margin: 2px;
  }

  th {
    font-weight: bold;
  }

  tr {
    border-bottom: 1px solid $black;
  }

  thead tr {
    border-bottom: 2px solid $black;
    background: rgba($gray, 0.5);
  }

  tbody tr {
    &:nth-child(odd) {
      background: rgba($white, 0.5);
    }
    &:nth-child(even) {
      background: rgba($gray, 0.5);
    }
  }

  tbody tr:last-child {
    border: none;
  }
}

.static_page {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
  background-color: $xi-static-page-background-color;

  .last_changed {
    font-style: italic;
  }

  &__header {
    background-color: $pages-header-bg;

    h1 {
      @include font-m;
      margin: 30px auto;
      color: $pages-header-title-color;
      font-weight: normal;
      @include screen-size-s-and-up {
        @include font-l;
      }
    }
  }
}

.conclusion-hack,
.proctoring-statics-conclusion-hack {
  @include heavy-bar(border-top);

  margin-top: 20px;
  margin-bottom: 20px;
}
