.footer {
  border-top: 1px solid $gray-250;

  &__copyright {
    color: $gray-500;
    a {
      color: $gray-500;
    }
  }

  &__release {
    color: $gray-250;
  }

  &__owner {
    margin-right: 2px;
    text-decoration: underline;
  }
}
