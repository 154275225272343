//------------------------------------
//    $CONTENTS
//
//    Module imports for openHPI.
//    (Xikolo base style)
//------------------------------------

// Color schema definition
@import "theme/color_schema";

// Variables, mixin and function declarations
@import "stylesheets/theme/common/variables";
@import "stylesheets/theme/common/helpers";

// Extracted common design parts for easier reusability and change
@import "theme/design";

// Font variables and mixins for the theme
@import "theme/fonts";

$xi-footer-background: $gray-50;
$xi-footer-color: $gray-800;
$xi-footer-box-shadow: none;
$xi-footer-link-hover-color: $gray-500;

// MAIN navigation
$navbar-breakpoint-m: 1200px;
$navbar-breakpoint-sm: 950px;
$navbar-breakpoint-s: 720px;
