/* -------------------------------------------- *\
    $GLOBAL-BRAND-DEFINITIONS

    Xikolo font variables
\* -------------------------------------------- */

$basefont: "IBM Plex Sans", sans-serif;
// We use only the basefont throughout the application as the
// monopace definitions from WLDX does't fit our corporatefont usages.
// The monopace will be introduced later for the specific places.
$corporatefont: $basefont;

// Fonts
$xi-font-headings: $basefont, sans-serif;
$xi-font-buttons: $basefont, sans-serif;
$xi-font-navigation: $basefont, sans-serif;

$xi-font-footer: $basefont, sans-serif;
$xi-font-footer-link: $basefont, sans-serif;

$xi-font-table-title: $basefont;

$xi-font-form-inputs: $basefont;
$xi-font-form-labels: $basefont;

$xi-course-card-font: "IBM Plex Mono";

// Font-size
$xi-font-size-text: $font-size-sm;
$xi-font-size-small: $font-size-s;
$xi-font-size-title: $font-size-m;
