.disclosure-widget {
  padding: 10px;
  background-color: $gray-50;

  &--light {
    padding: 10px 10px 0 0;
    background-color: transparent;

    .disclosure-widget__summary {
      color: $gray-500;
      font-size: $font-size-xs;
    }
  }

  // Remove the disclosure triangle for WebKit browsers
  summary::-webkit-details-marker {
    display: none;
  }

  &__summary--opened {
    display: none;
  }

  &[open] {
    .disclosure-widget__summary--closed {
      display: none;
    }
    .disclosure-widget__summary--opened {
      display: flex;
    }
  }

  &__summary {
    display: flex;
    align-items: center;
    cursor: pointer;

    &--opened {
      display: none;
    }
  }

  & > div {
    padding-top: 10px;
    margin-top: 0;
  }
}
