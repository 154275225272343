.navigation-tabs {
  margin: 0 10px 20px 0;
  box-shadow: 0 1px 0 $gray-250;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  & &__header {
    display: block;

    @include screen-size-sm-and-up {
      display: none;
    }
  }

  & &__header-button {
    width: 100%;
    padding: 15px 10px;
    border: none;
    background-color: transparent;
    color: $text-color;
    font-size: $font-size-sm;
    text-align: right;

    &:hover,
    &:focus {
      background-color: $primary-100;
      cursor: pointer;
    }

    &:active {
      background-color: $primary-250;
    }
  }

  & &__items {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
  }

  & &__additional-items {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-end;
  }

  & &__content {
    @include screen-size-sm-and-up {
      display: flex;
      justify-content: space-between;
    }
  }

  & &__content--collapsible {
    .navigation-tabs__items {
      display: flex;
      flex-direction: column;

      @include screen-size-sm-and-up {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }

  .navigation-tabs__content--collapsible[aria-expanded="false"] {
    display: none;

    @include screen-size-sm-and-up {
      display: flex;
    }
  }

  & &__item {
    > * {
      display: flex;
      height: 50px;
      align-items: center;
      padding: 10px 20px;
    }

    a {
      color: $text-color;
    }

    .xi-icon {
      font-size: $font-size-sm;
    }

    &.navigation-tabs__item--active {
      background-color: $primary-50;
      box-shadow: inset 0 -2px 0 $primary-500;
    }

    &:hover,
    &:focus {
      background-color: $primary-100;
      cursor: pointer;
      > * {
        text-decoration: none;
      }
    }
  }

  & &__button {
    border: none;
    background-color: transparent;
  }
}
