.omniauth [data-provider="hpi"],
.omniauth [data-provider="hpi_saml"] {
  @extend %btn;
  @include btn-variant($white, $orange, darken($white, 15%));

  padding-left: 50px;
  margin-bottom: 0 !important;
  background-image: url("images/omniauth/hpi.png");
  background-position: 17px;
  background-repeat: no-repeat;
  vertical-align: middle;
}

.authorizations [data-provider="hpi"],
.authorizations [data-provider="hpi_saml"] {
  @extend %label-auth;

  background-color: $orange;
  background-image: url("images/omniauth/hpi.png");
}

.omniauth [data-provider="mein_bildungsraum"] {
  @extend %btn;
  @include btn-variant($white, #4dafff, darken($white, 15%));

  padding-left: 50px;
  margin-bottom: 0 !important;
  background-image: url("images/omniauth/mein_bildungsraum.png");
  background-position: 17px;
  background-repeat: no-repeat;
  vertical-align: middle;
}

.authorizations [data-provider="mein_bildungsraum"] {
  @extend %label-auth;

  background-color: #4dafff;
  background-image: url("images/omniauth/mein_bildungsraum.png");
}
