.transcript {
  position: relative;
  border: 1px solid $gray-900;
  font-size: 16px;

  &__cues {
    overflow: auto;
    height: 200px;
    padding: 0;
    margin: 0;
    list-style-type: none;

    & > * {
      display: flex;
      padding: 10px;
      border-top: 1px solid $gray-100;
      &:hover {
        color: $link-color;
      }
    }

    & > *[aria-current="true"] {
      background-color: $xi-transcript-highlighted-background-color;
    }
  }

  &__empty-state {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $gray-600;
    text-align: center;
    transform: translate(-50%, -50%);
  }

  &__start-time {
    margin-right: 15px;
  }
}
