/* ------------------------------------ *\
    $CONTENTS

    Styles for the BasicTooltip
    xui component.
\* ------------------------------------ */

.basic-tooltip-container {
  margin-top: 20px;
  margin-bottom: 10px;
}

.basic-tooltip-headline {
  display: inline;
  margin-right: 8px;
  vertical-align: baseline;
}

.basic-tooltip {
  position: relative;
  display: inline-block;
}

.basic-tooltip .basic-tooltip-icon {
  color: rgba(0, 0, 0, 0.54);
  vertical-align: baseline;
}

.basic-tooltip .basic-tooltip-text {
  position: absolute;
  z-index: 1;
  top: 140%;
  left: 50%;
  width: 250px;
  padding: 5px;
  border-radius: 6px;
  background-color: $gray-600;
  color: $white;
  text-align: center;
  transform: translateX(-50%);
  visibility: hidden;

  p:last-child {
    margin-bottom: 0;
  }
}

.basic-tooltip .basic-tooltip-text::after {
  position: absolute;
  bottom: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $gray-600 transparent;
  margin-left: -5px;
  content: "";
}

.basic-tooltip:hover .basic-tooltip-text {
  visibility: visible;
}
