// Styles and overrides from dropzone.scss for the image upload dropzone
// (related js: image.upload.js, related markdown: image_upload_input.rb)

.xui-imageupload {
  display: flex;

  .dropzone {
    flex-grow: 1;
  }

  .imageupload-current .dz-preview {
    min-width: 300px;
    min-height: 300px;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 0;
    gap: 0;

    [data-dz-thumbnail],
    .dz-details {
      max-width: 300px;
    }
  }

  .dz-actions .dz-clear {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px 5px;
  }

  .to-be-removed {
    opacity: 0.4;
  }

  .dz-details {
    margin-bottom: 5px;
  }

  // Hide image details and status indicators after save
  .dropzone-previews .dz-file-preview {
    .dz-details,
    .dz-error-mark,
    .dz-success-mark {
      display: none;
    }
  }

  // Keep showing image preview after save
  .dropzone-previews .dz-preview.dz-file-preview {
    [data-dz-thumbnail] {
      display: block;
      max-width: 300px;
    }
  }
}
