.account-notifications {
  padding-top: 20px;

  h1 {
    color: $gray-900;
    @include font-m;
    font-weight: 400;
  }

  h2 {
    margin-top: 30px;
    color: $gray-900;
    font-weight: 400;
    @include font-sm;
  }

  &__preferences {
    margin-top: 10px;
  }

  &__table {
    width: 100%;

    th,
    td {
      padding: 6px 6px 6px 0;
      &:first-child {
        width: 100%;
      }
      &:not(:first-child) {
        padding-right: 40px;
        white-space: nowrap;
      }
    }

    tr:last-child td {
      border-bottom: 0px;
    }
  }

  th {
    @include light-bar("border-bottom");
  }

  td {
    @include light-bar("border-bottom", 1px);
    border-color: $gray-100;
  }

  label {
    display: block;
    margin-top: 0;
    cursor: pointer;
    font-weight: normal;
  }

  &__subscriptions {
    padding-top: 20px;
  }
}
