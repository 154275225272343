.PageIntro {
  padding: 20px 10px;
  background: $white;

  * {
    margin: 0;
  }
}

.PageIntro-title {
  color: $text-color;
  font-size: 2rem;
  font-weight: bold;
}

.PageIntro-lead {
  margin-top: 1em;
  font-size: 1.8rem;
  line-height: 1.5;

  * + * {
    margin-top: 1em;
  }
}

.CourseAchievementsList {
  > * {
    margin: 0 auto 20px;
  }
}

.Achievement {
  padding: 20px;
  background-color: #e7eff4;
  box-shadow: $box-shadow-xs;

  &.is-achieved {
    background-color: $primary-50;
  }

  > * {
    margin: 0;
  }
  > * + * {
    margin-top: 20px;
  }
}

.Achievement-Title {
  color: $gray-600;

  & {
    padding-bottom: 10px;
    border-bottom: 2px solid $gray-250;
  }

  .is-achieved & {
    border-color: $primary-color;
    color: $black;
  }
}

.Achievement-Description {
  color: $gray-600;

  .is-achieved & {
    color: $text-color;
  }
}

.Achievement-Requirements {
  ul {
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
    margin-top: 5px;
    margin-right: 20px;
    margin-left: 20px;
    color: $text-color;

    .icon,
    .xi-icon {
      width: 20px;
      margin-left: -20px;
      text-align: center;
    }

    &.is-achieved {
      border-bottom: 2px solid $success-500;
    }

    &.is-missing {
      border-bottom: 2px solid $error-600;
    }

    .is-in-progress &.is-missing {
      border-bottom-color: $information-500;
    }
  }
}

.Achievement-State {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 100px;
  margin-left: 20px;
  background-color: $white;
  box-shadow: $box-shadow-xs;
  font-size: 0.8em;
  font-weight: bold;
  vertical-align: top;

  .xi-icon {
    margin-right: 5px;
    opacity: 0.7;
  }

  .is-achieved & {
    border-color: $white;
    color: $success-500;
  }

  .is-in-progress & {
    color: $information-500;
  }
}

.Achievement-Actions {
  > * {
    display: block;
    width: 100%;
    margin-top: 10px;
    font-size: 1.2em;
  }

  p {
    color: $gray-600;
    font-size: 1em;
    font-weight: bold;

    .xi-icon {
      margin-right: 5px;
      font-size: 2rem;
      opacity: 0.7;
      vertical-align: middle;
    }
  }
}

@include small {
  .Achievement-Actions > * {
    display: inline-block;
    width: auto;
    margin-top: 0;
    margin-right: 20px;
  }
}
